import { AlertProps } from "src/components"
import { getApplicationParentId } from "src/helpers"
import { Application, CreditProposalType } from "src/types"

const defaultProps: AlertProps = {
    children: 'Estamos revisando tu expediente. Te notificaremos cuando puedas continuar tu solicitud',
    variant: 'outlined'
}

export const getAlertPropsByApplication = ({
    application,
    isPriority,
    canContinue,
    currentSectionLink,
    hasActiveCredit,
    hasRequiredDocuments,
    creditProposals,
    isKredi,
    currentCustomerId,
}: {
    application?: Application
    isPriority?: boolean
    canContinue?: boolean
    currentSectionLink?: string
    hasActiveCredit?: boolean
    hasRequiredDocuments?: boolean
    creditProposals: CreditProposalType[]
    isKredi?: boolean
    currentCustomerId?: string
}): AlertProps => {
    const hasSentCreditProposal = creditProposals.some(({ status }) => status === 'sent')
    const hasAcceptedCreditProposal = creditProposals.some(({ status }) => status === 'accepted')
    const hasApplicationParent = Boolean(getApplicationParentId(application))

    //If there is no application?.customer?.id, it means that is the last current customer application
    const isApplicationOwner = application?.customer?.id ? currentCustomerId === application?.customer?.id : true

    if (hasActiveCredit) {
        if (isKredi)
            return {
                children: 'Tienes un crédito activo. Dale seguimiento a tu crédito: pagos, asesoría, estados de cuenta.',
                variant: 'contained',
                actionProps: {
                    title: 'Ver detalles',
                    linkProps: {
                        to: '/home/credit'
                    }
                }
            }
        else
            return { children: 'Actualmente tienes un crédito activo', variant: 'outlined' }
    }

    if (hasAcceptedCreditProposal) {
        return {
            children: 'Paga el avalúo de la propiedad para continuar con la solicitud',
            variant: 'contained',
            actionProps: {
                title: 'Ir a pagos',
                linkProps: {
                    to: `/home/applications/${application?.id}/payments`
                }
            }
        }
    }

    switch (application?.status) {
        default:
            return defaultProps

        case 'draft':
        case 'in_process':
        case 'action_required': {

            if (hasRequiredDocuments
                && application?.status !== 'draft'
                && !canContinue
                && !isPriority
            ) {
                return {
                    children: 'Hay documentos que necesitan tu atención',
                    variant: 'contained',
                    actionProps: {
                        title: 'Ir a documentos',
                        linkProps: {
                            to: buildDocumentsPath(application?.id, isPriority, canContinue, currentSectionLink)
                        }
                    }
                }
            }

            return canContinue ? {
                children: 'Continua con la solicitud de tu crédito',
                variant: 'contained',
                actionProps: {
                    title: 'Continuar solicitud',
                    linkProps: {
                        to: currentSectionLink || '#'
                    }
                }
            } : defaultProps
        }

        case 'rejected': {
            return {
                children: 'Lo sentimos, tu solicitud fue rechazada. ¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.',
                variant: 'outlined'
            }
        }

        case 'cancelled': {
            return {
                children: 'Lo sentimos, tu solicitud fue cancelada. ¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.',
                variant: 'outlined'
            }
        }

        case 'approved': {
            // On send credit proposal from "portal", the application status it's expected to be 'approved' and has credit proposals
            return !isKredi && hasSentCreditProposal ? {
                children: '¡Tenemos una propuesta de crédito disponible para ti!',
                variant: 'contained',
                actionProps: {
                    title: 'Ver propuesta',
                    linkProps: {
                        to: `/home/applications/${application.id}/proposals`
                    }
                }
            } : {
                children: hasApplicationParent
                    ? isApplicationOwner
                        ? 'Tu solicitud ha sido aprobada. Consulta la solicitud principal para más información.'
                        : 'Tu coacreditado ha sido aprobado. Consulta la solicitud principal para más información.'
                    : '¡Felicidades! Tu solicitud ha sido aprobada. Pronto recibirás tu propuesta de crédito.',
                variant: 'outlined',
            }
        }
    }
}

function buildDocumentsPath(applicationId?: string, isPriority?: boolean, canContinue?: boolean, currentSectionLink?: string) {
    if (!applicationId) return '#'

    // If the current stage is a priority and the application can continue,
    // the user should be redirected to the current section link to continue the application
    return isPriority && canContinue && currentSectionLink
        ? currentSectionLink
        : `/home/applications/${applicationId}/documents`
}