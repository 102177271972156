import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { Sidebar, StagePage, FormBuilder, Loading, ConfirmModal, FormBuilderProps, SnackbarVariant } from "src/components";
import { useStyles } from "./styles";
import { getInitialValues, getValidationSchema, translateAssociation } from "@kredimx/form-builder";
import { ChevronLeft as LeftIcon, ChevronRight as RightIcon } from "@mui/icons-material";
import { ApplicationWorkflowSection } from "src/types";
import { useQueryParams, segment, useApplicationPusher, useIsMobile, downloadLocalFile, navigate } from "src/helpers";
import { ApplicationService } from "src/services";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { questionActions } from "src/store/questions/actions";
import { sectionActions } from "src/store/sections/actions";
import { applicationActions } from "src/store/application/actions";
import { providersActions } from "src/store/providers/actions";
import { snackbarActions } from "src/store/snackbar/actions";
import { bankAccountsActions } from "src/store/bankAccounts/actions";
import { attachmentsActions } from "src/store/attachments/actions";

import { getCustomer, getDefaultCustomerEmployment } from "./helpers";

import { Summary } from './Summary'
import { SectionsDrawer } from "./SectionsDrawer";
import { FormCustomActions } from "./CustomActions";
import { ApplicationSidebar } from "./Sidebar";

const {
	REACT_APP_ENV,
	REACT_APP_INCODE_URL,
	REACT_APP_VALIDATE_EMAIL
} = import.meta.env

export function Application() {
	const { applicationId }: any = useParams();
	const query: any = useQueryParams();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const isMobile = useIsMobile();

	useApplicationPusher({ getDefaultCustomerEmployment, getCustomer })

	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded);
	const { application, isLoading, isUpdating, showSummary, summaryAlreadyShown } = useSelector(state => state.applications)
	const { data: questions, isLoading: isLoadingQuestions } = useSelector(state => state.questions)
	const { current_section, current_stage, currentStageSections, applicationWorkflowStages, current_values } = application;
	const currentApplicationWorkflowStage = applicationWorkflowStages?.find(applicationWorkflowStage => applicationWorkflowStage.stage_id === current_stage?.id);

	const { data: municipalities, isLoading: isLoadingMunicipalities } = useSelector(state => state.providers.municipalities);
	const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useSelector(state => state.providers.documentTypes)
	const { data: countries, isLoading: isLoadingCountries } = useSelector(state => state.providers.countries);
	const { data: states, isLoading: isLoadingStates } = useSelector(state => state.providers.states);
	const { data: banks, isLoading: isLoadingBanks } = useSelector(state => state.providers.banks)

	const verificationFlow = useSelector(state => state.providers.incode.verificationFlow)
	const isLoadingSession = useSelector(state => state.providers.incode.isLoadingSession)
	const incodeSessions = useSelector(state => state.providers.incode.sessions)
	const associations = useSelector(state => state.providers.associations);
	const incodeData = useSelector(state => state.providers.incode.data)
	const bankAccounts = useSelector(state => state.bankAccounts.data)

	const matiData = useSelector(state => state.providers.matiData);
	const organization = useSelector(state => state.organization);
	const belvo = useSelector(state => state.providers.belvo);
	const customer = application?.customer

	const [openDrawerSection, setOpenDrawerSection] = useState(false);
	const [persistedValues, setPersistedValues] = useState({});
	const [belvoAccountConfirmation, setBelvoAccountConfirmation] = useState({
		open: false,
		institutionName: '',
		credentialId: '',
		linkId: ''
	});
	const [associationConfirmation, setAssociationConfirmation] = useState({
		open: false,
		type: '',
		id: ''
	});

	const from = ["summary", "stage_summary"]
	const isLastSection = application?.next_section == null;
	const isFirstSection = application?.previous_section == null;
	const sectionsIndex = application.workflow?.config?.application_workflow_sections_index

	const applicationSections = _.sortBy(_.filter(currentStageSections, item => (
		item.application_workflow_stage_id === currentApplicationWorkflowStage?.id)
	), 'sort_order');

	const sidebarItems = applicationSections.map((section: ApplicationWorkflowSection) => ({
		id: section.id,
		section_id: section.section_id,
		status: section.status,
		name: section.title,
		to: `/application/${applicationId}/${current_stage?.slug}/${section.slug}${query.from ? '?from=' + query.from : ''}`,
		isMenuItem: true,
		disabled: section.status === 'pending' || (section.status === 'summary' && !canShowSummary)
	})).concat({
		id: '',
		section_id: 'summary',
		status: 'summary',
		name: 'Resumen',
		to: `/application/${applicationId}/${current_stage?.slug}/summary${query.from ? '?from=' + query.from : ''}`,
		isMenuItem: true,
		disabled: disableSummary()
	})

	const canShowSummary = summaryAlreadyShown || applicationSections
		.every(({ status }) => status === 'completed');

	const autoSubmitIncode = questions.filter(q => ![
		'text_help',
		'text_help_with_image',
		'text_help_with_title',
	].includes(q.field_type || ''))?.length == 1

	useEffect(() => {
		dispatch(applicationActions.continueApplication(applicationId));
	}, [applicationId])

	useEffect(() => {
		setPersistedValues(current_values);
	}, [current_values])

	useEffect(() => {
		return () => {
			dispatch(authActions.setLogRocketSession(''));
			dispatch(providersActions.setIncodeSessions())
		}
	}, [])

	useEffect(() => {
		setShowSummary(location.pathname.includes('/summary'));
		if (isSegmentLoaded) {
			segment.page('Application');
		}
	}, [location.pathname])

	useEffect(() => {
		if (isSegmentLoaded) {
			segment.page('Application');
		}
	}, [window.analytics])

	function onSubmit(values: any) {
		dispatch(questionActions.saveAnswers(values, { isLastSection }));
	}

	const goToSection = (section: ApplicationWorkflowSection) => {
		navigate.to(`/application/${applicationId}/${current_stage?.slug}/${section.slug}`)
		dispatch(sectionActions.setCurrentSection(section?.id!))
	}

	function getPreviuosSection() {
		dispatch(sectionActions.setCurrentSection(application.previous_section?.id!))
		segment.track('Application', 'Clicked', {
			id: applicationId,
			section: current_section,
			stage: current_stage,
			action: 'GetPreviusSection'
		})
	}

	function disableSummary() {
		return applicationSections.some(section => ['pending', 'incompleted', 'failed'].includes(section.status || ''))
	}

	function setShowSummary(show: boolean) {
		dispatch(applicationActions.set('showSummary', show))
	}

	function closeBelvoAccountConfirmation() {
		setBelvoAccountConfirmation({
			open: false,
			institutionName: '',
			credentialId: '',
			linkId: ''
		})
	}

	function closeAssociationConfirmation() {
		setAssociationConfirmation({
			open: false,
			type: '',
			id: ''
		})
	}

	const cancelButtonProps: FormBuilderProps['cancelButtonProps'] = {
		startIcon: <LeftIcon />,
		onClick: getPreviuosSection,
		className: cx(classes.cancelButton),
		disableElevation: false,
		color: 'primary',
		variant: 'outlined',
	}

	const submitButtonProps: FormBuilderProps['submitButtonProps'] = {
		endIcon: <RightIcon />,
		className: cx(classes.submitButton, {
			[classes.buttonWithResume]: canShowSummary
		}),
		dataCy: 'submit-button',
		disabled: isLoadingQuestions,
	}

	return (
		<>
			<ConfirmModal
				title="Eliminar cuenta"
				description={`¿Deseas eliminar la cuenta ${belvoAccountConfirmation.institutionName || ''}?`}
				open={belvoAccountConfirmation.open}
				onCancel={closeBelvoAccountConfirmation}
				onConfirm={() => {
					dispatch(providersActions.destroyBelvoCredential(belvoAccountConfirmation.credentialId));
					closeBelvoAccountConfirmation();
				}}
			/>

			<ConfirmModal
				title={`Eliminar ${translateAssociation[associationConfirmation.type]}`}
				description={`¿Deseas eliminar este registro?`}
				open={associationConfirmation.open}
				onCancel={closeAssociationConfirmation}
				onConfirm={() => {
					dispatch(providersActions.destroyAssociation(
						associationConfirmation.type,
						applicationId,
						associationConfirmation.id
					));
					closeAssociationConfirmation();
				}}
			/>

			<Grid container className={classes.container}>
				<ApplicationSidebar
					title={isMobile ? current_section?.title : current_stage?.name}
					nextSection={application.next_section}
					exactRoutes
					showStatusIcon
					hover
					isLoading={isLoading}
					isUpdating={isUpdating}
					isSummary={showSummary}
					sectionsIndex={sectionsIndex}
					currentSection={applicationSections.find(section => section.section_id === current_section?.id)}
					titleLink={from.includes(query.from) ? {
						to: `/home/main`,
						// to: `/home/applications/summary/${applicationId}/${query.from === 'summary' ? 'main' : 'stages'}`,
					} : undefined}
					items={sidebarItems}
					onItemClick={(item: ApplicationWorkflowSection) => {
						setShowSummary(item.section_id === 'summary')
						if (item.section_id !== 'summary') {
							dispatch(sectionActions.setCurrentSection(item.section_id!))
						}
					}}
					openSectionDrawer={() => {
						setOpenDrawerSection(true)
					}}
				/>

				<SectionsDrawer
					stageName={current_stage?.name}
					open={openDrawerSection}
					onClose={() => { setOpenDrawerSection(false) }}
					sectionsIndex={sectionsIndex}
					items={sidebarItems.filter(section => sectionsIndex == 'progress_line' ? !section.disabled : true)}
					exactRoutes
					showStatusIcon
					hover
					onItemClick={(item: ApplicationWorkflowSection) => {
						setShowSummary(item.section_id === 'summary')
						if (item.section_id !== 'summary') {
							dispatch(sectionActions.setCurrentSection(item.section_id!))
						}
					}}
				/>

				<div
					className={cx(classes.content, {
						[classes.contentWBanner]: (current_section?.slug === "crear-cuenta" && !showSummary)
					})}
				>
					<Loading
						isLoading={isLoading || isUpdating}
						className={classes.loadingContainer}
						text="Esto solo nos llevará un momento, <br/> gracias por continuar"
					>
						{showSummary && (
							<Summary
								goToSection={goToSection}
								applicationId={applicationId}
								currentStage={application.current_stage}
							/>
						)}

						{!showSummary && (
							<Grid container style={{ height: '100%' }}>
								<StagePage
									title={isFirstSection ? '' : current_section?.title}
									description={current_section?.description}
									className={cx(classes.stageContainer)}
									titleProps={{ className: classes.title }}
									rootContainerProps={{ className: classes.stageRoot }}
									titleContainerProps={{
										className: cx(classes.titleContainer, {
											[classes.titleWithoutDescription]: !current_section?.description
										})
									}}
									isApplication
								>
									<FormBuilder
										fields={questions}
										onSubmit={onSubmit}
										initialValues={getInitialValues(questions, {
											...application?.current_values,
											...persistedValues,
										})}
										validationSchema={getValidationSchema(questions)}
										cancelButtonProps={cancelButtonProps}
										cancelCaption="Volver"
										submitButtonProps={submitButtonProps}
										submitCaption={isFirstSection ? "Comenzar" : "Siguiente"}
										hideCancel={isFirstSection}
										actionContainerProps={{
											justifyContent: isFirstSection ? 'flex-end' : 'space-between',
											className: classes.formBuilderActions
										}}
										onConditionedChange={(fieldId: string, values: any, currentValues: any) => {
											setPersistedValues(currentValues);
											dispatch(questionActions.getConditionedQuestions(fieldId, values));
										}}
										itemContainerProps={{
											style: {
												marginBottom: 32
											},
										}}
										actionsChildren={canShowSummary && (
											<FormCustomActions
												hideCancel={isFirstSection}
												cancelButtonProps={cancelButtonProps}
												submitButtonProps={submitButtonProps}
											/>
										)}
										formProps={{
											className: cx('text', classes.fieldsContainer, {
												[classes.fieldsContainerFirstSection]: isFirstSection,
											}),
										}}
										setActionsOutsideFieldsContainer
										config={{
											env: REACT_APP_ENV,
											validateEmail: REACT_APP_VALIDATE_EMAIL,
											incodeUrl: REACT_APP_INCODE_URL,
											validateDuplicatedFiles: REACT_APP_ENV != 'staging'
										}}
										snackbar={{
											add: (type: SnackbarVariant, message: string) => dispatch(snackbarActions.add(type, message)),
											clean: () => dispatch(snackbarActions.clean())
										}}
										specialInputsProps={{
											countryInputProps: {
												isLoading: isLoadingCountries,
												countries: countries || [],
												getCountries: () => dispatch(providersActions.getCountries()),
												setCountries: (countries?: any[]) => dispatch(providersActions.setCountries(countries)),
												getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
												setDependenciesValues(options: any) {
													dispatch(questionActions.cleanCountryInputs(options))
												}
											},
											stateInputProps: {
												isLoading: isLoadingStates,
												countries: countries || [],
												states: states || [],
												getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
												setStates: (states?: any[]) => dispatch(providersActions.setStates(states)),
												getMunicipalities: (state?: string) => dispatch(providersActions.getMunicipalities(state)),
												fields: questions,
											},
											municipalityInputProps: {
												isLoading: isLoadingMunicipalities,
												municipalities: municipalities || [],
												states: states || [],
												getMunicipalities: (state?: string) => dispatch(providersActions.getMunicipalities(state)),
												setMunicipalities: (municipalities?: any[]) => dispatch(providersActions.setMunicipalities(municipalities)),
												fields: questions,
											},
											belvoInputProps: {
												belvo,
												customer,
												organization,
												applicationId,
												sendBelvoData: (metadata: any) => dispatch(providersActions.sendBelvoData(metadata)),
												getBelvoToken: (credentialId?: string) => dispatch(providersActions.getBelvoToken(credentialId)),
												getCredentials: (linkId: string) => dispatch(providersActions.getBelvoCredentials(linkId)),
												onCredentialDelete: (institutionName: string, credentialId: string, linkId: string) => {
													setBelvoAccountConfirmation({ open: true, institutionName, credentialId, linkId })
												},
												onUnmount: () => dispatch(providersActions.cleanBelvo())
											},
											documentVerificationInputProps: {
												matiData,
												customer,
												organization,
												application,
												applicationId: application?.id,
												getMatiData: (flowId: string) => dispatch(providersActions.getMatiData(flowId)),
												setMatiData: () => dispatch(providersActions.setMatiData()),
												uploadAttachment: async (attachment: any, application: any, id: string) => {
													return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment);
												},
												user: customer,
												userType: 'Customer',
											},
											verificationInputProps: {
												customer,
												onResend: () => dispatch(applicationActions.sendSmsCode(applicationId)),
												onConfigPhone: () => { getPreviuosSection() },
												currentValues: current_values,
											},
											zipCodeInputProps: {
												onCodeChange: async (value: string, { currentValues, fieldKey, keys, isValid }) => {
													dispatch(questionActions.getAddressByZipCode(
														value,
														keys,
														fieldKey,
														currentValues,
														isValid
													))
												}
											},
											uploadInputProps: {
												getAttachment:
													async (application: any, id: string) => {
														return await ApplicationService.getPath(`${application.id}/answers/${id}`,);
													},
												uploadAttachment:
													async (attachment: any, application: any, id: string) => {
														return await ApplicationService.patchPath(`${application.id}/answers/${id}/manage`, attachment);
													},
												downloadAttachment: (attachment) => {
													dispatch(questionActions.downloadFile(attachment.file_name, attachment.file_url))
												},
												resetAnswer: async (application, answer) => {
													return await ApplicationService.destroyPath(`${application.id}/answers/${answer.id}/purge`, '');
												},
												getDocumentTypes() {
													dispatch(providersActions.getDocumentTypes())
												},
												application,
												user: customer,
												userType: 'Customer',
												documentTypes,
												visibility: 'customer'
											},
											associationsInputProps: {
												applicationId,
												customerId: customer?.id || '',
												associations,
												getAssociations: (type: string, applicationId: string) => {
													dispatch(providersActions.getAssociations(type, applicationId))
												},
												onCreate: (type: string, applicationId: string, association: any) => {
													dispatch(providersActions.createAssociation(type, applicationId, association))
												},
												onUpdate: (type: string, applicationId: string, id: string, association: any) => {
													dispatch(providersActions.updateAssociation(type, applicationId, id, association))
												},
												onDelete: (type: string, applicationId: string, id: string) => {
													setAssociationConfirmation({ open: true, type, id })
												},
												onUnmount: (type: string) => {
													dispatch(providersActions.setAssociations(type))
												}
											},
											termsAndConditionsInputProps: {
												organization
											},
											emailInputProps: {
												// emailValidationRequest: async (email: string) => (
												// 	sendgridClient.request({
												// 		url: `/v3/validations/email`,
												// 		method: 'POST',
												// 		body: { email, source: 'signup' }
												// 	})
												// )
											},
											bankInputProps: {
												getBanks() {
													dispatch(providersActions.getBanks())
												},
												setBanks(banks) {
													dispatch(providersActions.setBanks(banks))
												},
												isLoading: isLoadingBanks,
												banks,
											},
											incodeInputsProps: {
												sessions: incodeSessions,
												organization,
												application,
												customerId: customer?.id || '',
												customer,
												verificationFlow,
												apiKey: incodeData?.api_key,
												isLoadingSession,
												interviewId: _.isArray(incodeSessions) && incodeSessions.length > 0 ? (incodeSessions.find(s => s?.interview_id)?.interview_id) : undefined,
												getIncodeData() {
													dispatch(providersActions.getIncodeData())
												},
												getVerificationFlow(flowId) {
													dispatch(providersActions.getIncodeVerificationFlow(flowId))
												},
												getSession(applicationWorkflowId, applicationId) {
													dispatch(providersActions.getIncodeSessions(applicationWorkflowId, applicationId))
												},
												upsert(data) {
													dispatch(providersActions.incodeUpsert(data))
												},
												onUnmount() {
													dispatch(providersActions.setIncodeSessions())
												},
												uploadAttachment: async (attachment: any, application: any, id: string) => {
													return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment);
												},
												user: customer,
												userType: 'Customer',
												onError(details) {
													dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
												},
												submitOnUploadDocuments: autoSubmitIncode
											},
											bankStatementInputProps: {
												application,
												customer,
												user: customer,
												userType: 'Customer',
												submitOnUploadDocuments: autoSubmitIncode,
												upsert: (data: any) => {
													dispatch(providersActions.krediUpsert(data))
												},
												uploadAttachment: async (attachment: any, application: any, id: string) => {
													return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment);
												},
												onError(details) {
													dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
												},
												async getAttachment(application: any, id: string) {
													return await ApplicationService.getPath(`${application.id}/answers/${id}`,);
												},
											},
											taxIdStatusInputProps: {
												application,
												customer,
												user: customer,
												userType: 'Customer',
												submitOnUploadDocuments: autoSubmitIncode,
												upsert: (data: any) => {
													dispatch(providersActions.krediUpsert(data))
												},
												uploadAttachment: async (attachment: any, application: any, id: string) => {
													return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment);
												},
												onError(details) {
													dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
												},
												async getAttachment(application: any, id: string) {
													return await ApplicationService.getPath(`${application.id}/answers/${id}`,);
												},
											},
											curpInputProps: {
												customer,
												async getCurp(fields) {
													return await ApplicationService.createPath(`${applicationId}/action/fiscal_data`, {
														payload: fields
													}, false)
												},
											},
											rfcInputProps: {
												async getRfc(fields) {
													return await ApplicationService.createPath(`${applicationId}/action/fiscal_data`, {
														payload: fields
													}, false)
												},
											},
											phoneConfirmationProps: {
												async onUpdate(payload) {
													dispatch(applicationActions.sendSmsCode(applicationId, payload))
												},
												async onMount() {
													dispatch(snackbarActions.add('success', 'Te enviamos el código'))
												}
											},
											multiBankAccountInputProps: {
												bankAccounts,
												getBankAccounts() {
													dispatch(bankAccountsActions.getAll(applicationId))
												},
												createBankAccount(bankAccount, formData) {
													dispatch(bankAccountsActions.create(applicationId, formData))
												},
												updateBankAccount(bankAccount, formData) {
													dispatch(bankAccountsActions.update(applicationId, bankAccount.id!, formData))
												},
												destroyBankAccount(bankAccount, formData) {
													dispatch(bankAccountsActions.destroy(applicationId, bankAccount.id!))
												},
												downloadAttachment(attachment: any) {
													if (attachment.name as any) {
														downloadLocalFile(attachment as File, attachment.name)
													} else {
														dispatch(attachmentsActions.download(attachment))
													}
												},
												disableDuplicatedValidation: REACT_APP_ENV === 'development',
											},
											addressAutocompleteInputProps: {
												apiKey: organization?.installed_apps?.google_maps?.api_key,
											}
										}}
									/>
								</StagePage>
							</Grid>
						)}

					</Loading>
				</div>
			</Grid>
		</>
	)
}