import { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Dialog, DialogProps } from 'src/components'
import { ApplicationService } from 'src/services'
import { ActionableType, CustomSwitchableEvaluatorQualifierField } from 'src/types'
import { UploadComponent } from './InputComponent'
import { buildAttachment } from './helpers'
import { useStyles } from './styles'
import _ from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import { snackbarActions } from 'src/store/snackbar/actions'
import { applicationEvaluatorFieldsActions } from 'src/store/application/actions'
import { fieldsActions } from 'src/store/fields/actions'
import { TextHelpWithImage } from '@kredimx/form-builder'

interface DocumentDialogProps {
    approval: CustomSwitchableEvaluatorQualifierField
    dialogProps: DialogProps
}

export function DocumentDialog({ dialogProps, approval }: DocumentDialogProps) {
    const { classes } = useStyles()
    const dispatch = useDispatch()

    const customer = useSelector(state => state.auth.customer)
    const user = useSelector(state => state.auth.customer.user)
    const organization = useSelector(state => state.organization)
    const application = useSelector(state => state.applications.application)
    const approvalsMeta = useSelector(state => state.applications.switchableEvaluators.meta)
    const helperField = useSelector(state => state.fields.field)

    const [isUploading, setIsUploading] = useState(false)

    async function handleUpload(attachment?: FormData, id?: string) {
        if (!attachment) return

        setIsUploading(true)

        try {
            const formattedAttachment = buildAttachment(attachment, approval)
            await ApplicationService.patchPath(`${application.id}/answers/manage`, formattedAttachment)
            dialogProps?.onClose?.()
            setIsUploading(false)

            dispatch(snackbarActions.add('success', 'Documento cargado correctamente'))
            dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(application.id!, approvalsMeta))
        } catch {
            setIsUploading(false)
            console.log("error ==>")
            dispatch(snackbarActions.add('error', 'Lo sentimos, ha ocurrido un error'))
        }
    }

    useEffect(() => {
        if (approval?.field?.config?.helper_field_id) {
            dispatch(fieldsActions.getOne(approval?.field?.config?.helper_field_id))
        }

        return () => {
            dispatch(fieldsActions.setOne())
        }
    }, [approval?.field?.config?.helper_field_id, dialogProps?.open])

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            closeButtonProps={{ className: classes.headerButton }}
            open={dialogProps.open}
            onClose={(_, reason) => {
                if (reason === 'backdropClick') {
                    return
                }
                dialogProps?.onClose?.(_, reason)
            }}
        >
            <Grid
                container
                spacing={{ xs: 4, md: 3 }}
            >
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {approval?.field?.label || ''}
                    </Typography>
                </Grid>

                {helperField?.id && (
                    <Grid item xs={12}>
                        <TextHelpWithImage {...helperField} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <UploadComponent
                        hideLabel={Boolean(approval?.field?.label)}
                        application={application}
                        applicationService={ApplicationService}
                        actionableId={approval?.actionable_id || ''}
                        actionableType={approval?.actionable_type as ActionableType}
                        approval={approval}
                        approvalId={approval.id}
                        customer={customer}
                        dispatch={dispatch}
                        fieldKey={approval?.field?.key || ''}
                        isUploading={isUploading}
                        key={approval?.id}
                        type={approval?.field?.field_type || ''}
                        onUpload={(attachment, _, id) => handleUpload(attachment, id)}
                        organization={organization}
                        setIsUploading={setIsUploading}
                        user={user}
                        actionable={{
                            id: approval?.actionable_id || '',
                            type: approval?.actionable_type as ActionableType
                        }}
                    />
                </Grid>
            </Grid>
        </Dialog>
    )
}