import { useSelector } from "react-redux";
import { Application } from "src/types";

export function useIsApplicationOwner(application?: Application) {
    const currentCustomer = useSelector(state => state.auth.customer)

    // If there is no application?.customer?.id, it means that is the last current customer application
    return application?.customer?.id
        ? application?.customer?.id === currentCustomer?.id
        : true
}