import { ComponentType, Fragment } from "react";
import { PendingIcon, WarningIcon } from "src/assets/icons";
import AppStatusDefaultImg from 'src/assets/img/application_status_solicita.svg';
import {
    Check as CheckIcon,
    CalendarToday as CurrentIcon,
    Timelapse as IncompleteIcon,
    AccessTimeFilled as Pending2Icon,
    InfoOutlined as InfoIcon,
    Close as CloseIcon,
} from "@mui/icons-material"

export function getStatusIcon(status?: string) {
    switch (status) {
        case 'completed':
        case 'approved':
            return <CheckIcon color="primary" />;
        case 'current':
            return <CurrentIcon />;
        case 'pending':
            return <PendingIcon />;
        case 'incompleted':
            return <IncompleteIcon />;
        case 'failed':
            return <WarningIcon />;
        case 'in_process':
        case 'draft':
            return <Pending2Icon />;
        case 'action_required':
            return <InfoIcon />;
        case 'rejected':
        case 'cancelled':
            return <CloseIcon />;
        default:
            return <div />
    }
}

export function getApplicationStatusIcon(status: string, isSummary?: boolean) {

    if (isSummary) {
        return status === 'summary' ? <div /> : <CheckIcon color="primary" />;
    }

    switch (status) {
        case 'published':
        case 'completed':
            return <CheckIcon color="primary" />;
        case 'current':
            return <div />;
        case 'failed':
            return <WarningIcon />;
        default:
            return <div />
    }
}

export function translateStatus(status?: string): string {
    switch (status) {
        case 'draft': return 'Borrador';
        case 'completed': return 'Completado';
        case 'current': return 'Actual';
        case 'pending': return 'Pendiente';
        case 'incompleted': return 'Incompleto';
        case 'failed': return 'Error';
        case 'published': return 'Publicada';
        case 'in_process':
        case 'in_review': return 'En proceso';
        case 'approved': return 'Aprobada';
        case 'rejected': return 'Rechazada';
        case 'cancelled': return 'Cancelada';
        case 'action_required': return 'Se requiere acción';
        default: return status || '';
    }
}

export function getStatusColor(status?: string, isStagesPage?: boolean): { color: string, background: string } {
    switch (status) {
        case 'approved':
        case 'completed':
            return { color: '#1DAA6C', background: '#E5F4EC' };
        case 'pending':
        case 'action_required':
            return isStagesPage ? { color: '#B4BAC3', background: 'rgba(117, 139, 183, 0.08)' } : { color: '#FF8D02', background: '#FFEFDC' };
        case 'in_process':
        case 'in_review':
        case 'published':
        case 'current':
            return { color: '#4AAAFF', background: '#E4F3FF' };
        case 'rejected':
        case 'failed':
            return { color: '#F44336', background: '#FFF1F1' };
        case 'incompleted':
            return { color: '#2869CB', background: '#D7E4FD' };
        case 'cancelled':
            return { color: '#FF782D', background: 'rgba(255, 196, 45, 0.1)' };
        default:
            return { color: '#8892A0', background: 'rgba(117, 139, 183, 0.08)' };
    }
}

export const StatusesSummary = [
    { value: "single", caption: "Soltero(a)" },
    { value: "married", caption: "Casado(a)" },
    { value: "divorced", caption: "Divorciado(a)" },
    { value: "separated", caption: "Separado(a)" },
    { value: "living_together", caption: "Unión libre" },
    { value: "widowed", caption: "Viudo(a)" },
    { value: "assalaried", caption: "Asalariado" },
    { value: "assimilated", caption: "Asimilados" },
    { value: "businessman", caption: "Empresario" },
    { value: "cash", caption: "En efectivo (Ingresos no comprobables)" },
    { value: "freelance", caption: "Profesionista Independiente" },
]

export function getApplicationStatusImage(status: string, isMobile: boolean): any {
    switch (status) {
        default:
            return AppStatusDefaultImg;
    }
}


/**
 * Returns any enum label by value
 * @param value 
 * @returns 
 */
export function translateEnumAnswer(value?: string): string {
    switch (value) {
        case 'single': return 'Soltero(a)';
        case 'married': return 'Casado(a)';
        case 'divorced': return 'Divorciado(a)';
        case 'separated': return 'Separado(a)';
        case 'living_together': return 'Unión libre';
        case 'widowed': return 'Viudo(a)';

        case 'male': return 'Masculino'
        case 'female': return 'Femenino'

        case 'assalaried': return 'Asalariado';
        case 'assimilated': return 'Asimilados';
        case 'commissioned': return 'Comisionado'
        case 'cash': return 'Recibo dinero en Efectivo (No tengo depósitos bancarios)';
        case 'businessman': return 'Recibo dividendos de mi empresa (utilidades)';
        case 'freelance': return 'Profesionista Independiente o Empresario';

        case 'conjugal_society': return 'Sociedad conyugal';
        case 'property_separation': return 'Separación total de bienes';

        case 'weekly': return 'Semanal'
        case 'biweekly': return 'Bisemanal'
        case 'fortnightly': return 'Quincenal'
        case 'monthly': return 'Mensual'

        case 'paysheet': return 'Nómina'
        case 'statement': return 'Estado de cuenta'
        case 'tax_return': return 'Declaración de impuestos'

        case 'leasing': return 'Arrendamiento'
        case 'commerce': return 'Comercio'
        case 'services': return 'Servicios'
        case 'manufacture': return 'Manufactura'
        case 'transportation': return 'Transporte'
        case 'gas_stations': return 'Gasolineros'

        case 'traditional_payroll': return 'Nómina tradicional'
        case 'assimilated_to_salary': return 'Asimilados a salarios'

        default: return value || '';
    }
}