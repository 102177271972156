import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApplicationResultURL, isValidId, navigate, segment } from "src/helpers";
import Pusher from 'pusher-js';

import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { authActions } from "src/store/auth/actions";
import { Customer, Employment } from "src/types";

const { REACT_APP_PUSHER_KEY, REACT_APP_LINKEDIN_CONVERSION_ID }: any = import.meta.env;

const pusher = new Pusher(REACT_APP_PUSHER_KEY!, {
    cluster: 'us3',
});

export interface ScoringSocketDataType {
    message: 'pre_approved' | 'rejected' | 'in_review' | 'approved',
    score_id?: string
    product_application_id?: string
    execution_status?: string
    stage_id?: string
    qualifier_slug?: string
    application_workflow_id?: string
    action_tag?: string
}

interface ApplicationPusherProps {
    getDefaultCustomerEmployment?: () => Promise<Employment>
    getCustomer?: () => Promise<Customer>
}

/**
 * Hook for receive application scoring result
 */
export function useApplicationPusher(props?: ApplicationPusherProps) {
    const { getDefaultCustomerEmployment, getCustomer } = props || {}
    const { applicationId }: any = useParams();
    const dispatch = useDispatch();

    const [channel, setChannel] = useState<any>();
    const application = useSelector(state => state.applications.application);
    const stage = application.applicationWorkflowStages?.find(stage => stage.stage_id === application.current_stage?.id) || {};
    const section = application.currentStageSections?.find(section => section.section_id === application.current_section?.id) || {};
    const channels = useSelector(state => state.applications.applicationPusherChannels);

    useEffect(() => {
        if (isValidId(applicationId)) {
            setChannel(pusher?.subscribe(applicationId));
        }
    }, [applicationId])

    useEffect(() => {
        if (application.id && channel && !channels.includes(application.id)) {
            dispatch(applicationActions.addApplicationPusherChannel(application.id));

            channel?.bind('score_status', async (data: ScoringSocketDataType) => {
                dispatch(authActions.setScoringData(data));
                pusher.unsubscribe(applicationId);

                if (
                    data?.message == 'pre_approved' ||
                    data?.message == 'approved' ||
                    data?.action_tag == 'approved' ||
                    data?.action_tag == 'pre_approved'
                ) {
                    let predeterminedEmployment: Employment = {}
                    let customer: Customer = {}

                    if (getDefaultCustomerEmployment) {
                        predeterminedEmployment = await getDefaultCustomerEmployment()
                    }

                    if (getCustomer) {
                        customer = await getCustomer()
                    }

                    segment.track('Application', 'Pre-approved', {
                        action: 'Application Pre-Approved',
                        status: 'pre_approved',
                        category: application?.workflow_title,
                        stage: application?.current_stage?.name,
                        stage_sort_order: stage.sort_order,
                        section: application?.current_section?.title,
                        section_sort_order: section?.sort_order,
                        requested_amount: application?.requested_amount,
                        approved_amount: application?.approved_amount,
                        credit_amount: application?.credit_amount,
                        location: 'Web App',
                        label: 'pre_approved',
                        revenue: predeterminedEmployment?.net_salary,
                        currency: 'MXN',
                        email: customer?.email || '',
                        phone: `${customer.phone_with_country_code?.phone_country_code || ''}${customer.phone_with_country_code?.phone || ''}`,
                    })

                    segment.lintrk({ conversion_id: REACT_APP_LINKEDIN_CONVERSION_ID })

                    navigate.to(getApplicationResultURL(application, { customStatus: 'pre_approved' }))
                } else {
                    navigate.to(getApplicationResultURL(application, { scoringData: data }))
                }
            })

            channel?.bind('scoring_quotation', (data: ScoringSocketDataType) => {
                if (data?.product_application_id) {
                    dispatch(authActions.set('scoringProductApplicationId', data.product_application_id))
                }
            })

        }
    }, [application.id, channel, channels, application.current_stage?.id])
}