import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        padding: 36,
        [theme.breakpoints.down('sm')]: {
            padding: '48px 16px 16px 16px'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        // top: '50%',
        // transform: 'translateY(-50%)',
        // width: '100%',
        '& > div': {
            textAlign: 'center'
        },
        // [theme.breakpoints.down('sm')]: {
        //     padding: 16
        // }
    },
    logoContainer: {
        padding: 40,
        position: 'absolute',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: 32,
        }
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            width: 91
        }
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 18,
        '& > svg': {
            maxWidth: '100%',
            height: '40vh',
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
            '& > svg': {
                height: 'auto',
            },
        }
    },
    title: {
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '36px',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            lineHeight: '24px',
        }
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '24px',
        }
    },
}));