import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
	title: {
		fontSize: '22px',
		fontWeight: 700,
		lineHeight: '24px',
		letterSpacing: '0.18px',
		textAlign: 'center',
		marginBottom: 32
	},
	button: {
		width: 120,
		height: 40,
		borderRadius: 20,
		margin: '0 16px',
		fontSize: 14,
		fontWeight: 700,
		lineHeight: '16px',
		letterSpacing: '0.12px',
		textTransform: 'none'
	},
	imgContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 24,
		width: '100%'
	},
	dialogTitle: {
		padding: 0
	}
}))