import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { images } from "src/helpers/images";
import { HomeButton, HomeButtonProps } from "../HomeButton";
import RightIcon from "@mui/icons-material/ChevronRightRounded"
import { applicationStatusDescription, applicationStatusTitle } from "./helpers";
import { Link } from "src/components";
import { useStyles } from "./styles";
import { Application } from "src/types";
import { RejectedReasons } from "../../Rejected/Reasons";

interface ApplicationStatusComponentProps {
    status: any;
    isLoading?: boolean;
    application?: Application;
    title?: string
    description?: string
    homeButtonProps?: HomeButtonProps
}

export function ApplicationStatusComponent({ status, isLoading, application, homeButtonProps, ...rest }: ApplicationStatusComponentProps) {
    const { classes } = useStyles()
    const organizationSlug = useSelector(state => state.organization?.slug);
    // @ts-ignore
    const Image: any = images[organizationSlug!].applicationStatus?.[status as any] || (() => <></>);
    const title = rest?.title || applicationStatusTitle[status];
    const description = rest?.description || applicationStatusDescription[status];

    const { id, current_section, current_stage, applicationWorkflowStages } = application || {}
    const currentApplicationWorkflowStage = applicationWorkflowStages?.find(stage => stage.stage_id === current_stage?.id)

    return (
        <Grid container>
            <Grid item xs={12}>

                <Grid container spacing={4} className={classes.container}>
                    <Grid item xs={12}>
                        <Image />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.title}> {title} </Typography>
                    </Grid>

                    {description && (
                        <Grid item xs={12}>
                            <Typography> {description} </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} className={classes.buttonsContainer}>

                        {['approved', 'preapproved'].includes(status) && (
                            <Link
                                to={`/application/${application?.id}/${currentApplicationWorkflowStage?.slug}/${current_section?.slug}?from=summary`}
                                disabled={isLoading}
                                className={classes.buttonLink}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={classes.button}
                                    disabled={isLoading}
                                    endIcon={<RightIcon />}
                                >
                                    {!isLoading && 'Continuar con la solicitud'}
                                    {isLoading && <CircularProgress size={24} />}
                                </Button>
                            </Link>
                        )}

                        {status === 'rejected' && (
                            <RejectedReasons />
                        )}

                        <HomeButton
                            isLoading={isLoading}
                            {...homeButtonProps}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}