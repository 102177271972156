import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from "@mui/material"
import { Fragment } from "react"

interface SkeletonProps extends MuiSkeletonProps {
    show?: boolean
    children?: React.ReactNode
}

export function Skeleton({ show, children, ...props }: SkeletonProps) {
    return show ? <MuiSkeleton {...props} /> : <Fragment>{children}</Fragment>
}