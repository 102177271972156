import { Action, Field } from "src/types"
import { FIELDS_TYPES } from "./types"

export interface FieldsStateType {
	isLoadingOne: boolean
	field?: Field
}

const INITIAL_STATE: FieldsStateType = {
	isLoadingOne: false,
}

export function fieldsReducer(state: FieldsStateType = INITIAL_STATE, action: Action): FieldsStateType {
	const { payload, type } = action

	switch (type) {

		case FIELDS_TYPES.GET_ONE: {
			return {
				...state,
				isLoadingOne: true,
			}
		}

		case FIELDS_TYPES.SET_ONE: {
			return {
				...state,
				isLoadingOne: false,
				field: payload.field
			}
		}

		default:
			return state
	}
}