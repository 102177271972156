import { Box, BoxProps, Button, Grid, Typography } from "@mui/material"
import { KeyboardArrowRight } from "@mui/icons-material"
import { Link } from "src/components"
import { CreditProposalType } from "src/types"
import { currencyString, useIsApplicationOwner } from "src/helpers"

import { ConceptList } from "./ConceptList"
import { CreditProposalStatusType } from "../../components/StatusConfirmation/helpers"
import { useStyles } from "./styles"
import { useSelector } from "react-redux"
import { Fragment } from "react"

interface ProposalCardProps {
    proposal: CreditProposalType
    boxProps?: BoxProps
    existsProposalAccepted: boolean
    setConfirmationModal: (proposalId: string, status: CreditProposalStatusType) => void
}

export function ProposalCard({
    proposal,
    boxProps,
    setConfirmationModal,
    existsProposalAccepted,
}: ProposalCardProps) {
    const { classes, cx } = useStyles()
    const currentCustomer = useSelector(state => state.auth.customer)
    const application = useSelector(state => state.applications.application)
    const isApplicationOwner = currentCustomer?.id === application?.customer?.id
    const { id: proposalId } = proposal

    const disableAction = [
        'accepted',
        'rejected',
        'expired'
    ].includes(proposal?.status || '') || existsProposalAccepted

    return (
        <Box {...boxProps} className={cx(classes.root, boxProps?.className)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box className={classes.header}>
                        <Typography className={classes.title}>
                            Propuesta de crédito
                        </Typography>
                        <Typography className={classes.requestedAmount}>
                            {currencyString(proposal?.max_approved_amount)} MXN
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <ConceptList proposal={proposal} />
                </Grid>

                {isApplicationOwner && (
                    <Grid item xs={12}>
                        <Box className={classes.buttonsContainer}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => setConfirmationModal(proposalId!, 'rejected')}
                                disabled={disableAction}
                            >
                                Rechazar propuesta
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => setConfirmationModal(proposalId!, 'accepted')}
                                disabled={disableAction}
                            >
                                Aceptar propuesta
                            </Button>
                            <Box style={{ flex: '100%', textAlign: 'center' }}>
                                <Link to={`${proposalId}`} underline="none">
                                    Ver detalle <KeyboardArrowRight className={classes.buttonLinkIcon} />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                )}

            </Grid>
        </Box>
    )
}