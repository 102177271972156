import { Application } from "src/types"
import { User } from "src/types/user"
import _ from "lodash"
import { ScoringSocketDataType } from "./hooks/useApplicationPusher"

/**
 * Use to check the difference days between date 1 and date 2
 * @param date1 Date time number 1
 * @param date2  Date time number 2
 * @returns Days number
 */
function getDifferenceInDays(date1: number, date2: number) {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60 * 60 * 24)
}

/**
 * Use to check if application status is cancelled or rejected and the application is not updated in 14 days (is expired)
 * @param application 
 * @returns 
 */
export function isExpiredStatus(application?: Application) {
    if (!application || !['rejected', 'cancelled'].includes(application.status || '')) {
        return false
    }

    const { cancelled_at, rejected_at, customer_updated_at } = application
    const expiredDate = application?.status === 'rejected' ? rejected_at : application?.status === 'cancelled' ? cancelled_at : null

    if (!expiredDate || !customer_updated_at) {
        return false
    }

    const daysDiff = getDifferenceInDays(new Date(expiredDate).getTime(), new Date(customer_updated_at).getTime())
    return daysDiff > 14
}

export const isCancelledOrRejected = (application?: Application) =>
    !_.isEmpty(application) && ['cancelled', 'rejected'].includes(application?.status || '')

export const getWhatsAppLinkWithMessage = (params: {
    application?: Application,
    user: User,
    origin?: 'lastOne' | 'current'
}) => {
    const { application, user, origin: applicationOrigin } = params || {}

    let link = `https://wa.me/${user?.phone?.replace('+', '')}`
    const isExpired = applicationOrigin === 'lastOne' ? isExpiredStatus(application) : false

    if (application?.internal_id && !isExpired) {
        const brokerName = `${user?.first_name || ''} ${user?.last_name || ''}`
        const message = `Hola ${brokerName} tengo dudas con mi solicitud de crédito, mi id es ${application.internal_id}`
        link += `?text=${message}`
    }

    return link
}

/**
 * Returns the parent application id
 * @param application 
 * @returns 
 */
export const getApplicationParentId = (application?: Application) =>
    application?.parent?.application_workflow_id || application?.parent?.id

/**
 * Returns the URL to redirect the user to the application result page
 * If application is predetermined, it will redirect to the received page
 * @param application 
 * @param options 
 * @returns 
 */
export function getApplicationResultURL(application?: Application, options?: {
    scoringData?: ScoringSocketDataType,
    customStatus?: string,
    applicationId?: string
}): string {
    const { scoringData, customStatus } = options || {}
    const applicationId = options?.applicationId || application?.id
    const isPredetermined = application?.predetermined_workflow || application?.workflow?.predetermined
    const scoringStatus = customStatus || (
        scoringData?.action_tag
            ? (scoringData?.action_tag == 'comite' || scoringData?.action_tag == 'committee'
                ? 'in_review'
                : scoringData?.action_tag)
            : scoringData?.message
    )

    return isPredetermined
        ? `/application/${applicationId}/received`
        : `/application/${applicationId}/status/response/detail?application_id=${applicationId}&application_status=${scoringStatus}`
}