import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Grid } from "@mui/material"
import { Loading, StagePage } from "src/components"
import { isValidId } from "src/helpers"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions, applicationEvaluatorFieldsActions } from "src/store/application/actions"

import { DocumentList, ProgressCards } from "./components"
import { useStyles } from "./styles"

export function DocumentsPage() {
    const { applicationId }: any = useParams()

    const { classes } = useStyles()
    const dispatch = useDispatch()

    const storeApplicationId = useSelector(state => state.applications.application?.id)
    const evaluators = useSelector(state => state.applications.switchableEvaluators?.data || [])
    const isLoadingApplication = useSelector(state => state.applications.isLoading)
    const isLoadingApprovals = useSelector(state => state.applications.switchableEvaluators.isLoading)
    const approvalsMeta = useSelector(state => state.applications.switchableEvaluators.meta)

    useEffect(() => {
        if (!isValidId(applicationId)) return

        if (!storeApplicationId || storeApplicationId !== applicationId) {
            dispatch(applicationActions.getApplication(applicationId))
        }

        dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(
            applicationId,
            { ...approvalsMeta }
        ))
    }, [applicationId])

    return (
        <>
            <StagePage
                title="Documentos"
                titleProps={{ className: classes.stageTitle }}
                titleContainerProps={{ className: classes.stageTitleContainer }}
                breadcrumbs={[
                    { name: 'Inicio', to: '/home/main' },
                    { name: 'Documentos' },
                ]}
            >
                <Loading
                    variant="skeleton"
                    isLoading={isLoadingApplication || isLoadingApprovals}
                >
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <ProgressCards applicationId={applicationId} />
                        </Grid>

                        <Grid item xs={12}>
                            <DocumentList />
                        </Grid>
                    </Grid>
                </Loading>
            </StagePage>
        </>
    )
}