import { useMemo, useState } from 'react'
import { Box, Grid, Typography, Zoom } from '@mui/material'
import { Card, Skeleton } from 'src/components'
import { Application, Customer } from 'src/types'
import { getApplicationParentId, getFullName, getStatusColor, getStatusIcon, translateStatus, useApplicationStage, useIsApplicationOwner } from 'src/helpers'
import { showShortcuts } from './helpers'
import { useStyles } from './styles'

import { ApplicationStatusAlert } from './Alert'
import { Shortcuts } from './Shortcuts'

import { useDispatch, useSelector } from 'react-redux'
import { applicationActions } from 'src/store/application/actions'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CurrentIcon from "@mui/icons-material/AccessTimeFilled"

interface DashboardCardProps {
    customer?: Customer
    application?: Application
    setCancelApplicationOpen: (open: boolean) => void
    setCoaccreditOpen: (open: boolean) => void
    isCurrentApplication?: boolean
}

export const DashboardCard = (props: DashboardCardProps) =>
    useMemo(() => <MemoDashboardCard {...props} />, [
        props?.application?.id,
        props?.customer?.id,
        props?.isCurrentApplication
    ])

export function MemoDashboardCard({
    customer: _customer,
    application,
    setCancelApplicationOpen,
    setCoaccreditOpen,
    isCurrentApplication
}: DashboardCardProps) {
    const { classes, cx } = useStyles()
    const isApplicationOwner = useIsApplicationOwner(application)
    const customer = useSelector(state => state.auth.customer)
    const isLoading = useSelector(state => state.applications.isPartialLoading)
    const { currentStage } = useApplicationStage({ application })

    const isPrivateStage = currentStage?.visibility == 'user'
    const title = isCurrentApplication
        ? customer?.first_name ? `¡Hola ${customer.first_name}!` : '¡Hola!'
        : application?.customer?.first_name ? getFullName(application?.customer) : 'Coacreditado'

    const status = getStatusColor(application?.status)
    const hasApplicationParent = Boolean(getApplicationParentId(application))

    return (
        <Card
            title={(
                <Skeleton show={isLoading} height={42} width={300}>
                    {title}
                </Skeleton>
            )}
            className={classes.root}
            titleProps={{ className: classes.title }}
            titleAction={(
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <Typography className={cx(classes.stage, classes.textIcon)}>
                        <Skeleton show={isLoading} height={18} width={150}>
                            <InfoIcon />
                            {currentStage?.visibility == 'user' ? 'Revisión de documentos' : application?.current_stage?.name}
                        </Skeleton>
                    </Typography>
                    <Typography
                        className={cx(classes.status, classes.textIcon)}
                        style={{ color: status.color }}
                    >
                        <Skeleton show={isLoading} height={14} width={80}>
                            {isPrivateStage && application?.status == 'in_process' ? <CurrentIcon /> : getStatusIcon(application?.status)}
                            {isPrivateStage && application?.status == 'in_process' ? 'En revisión' : translateStatus(application?.status)}
                        </Skeleton>
                    </Typography>
                </Box>
            )}
        >
            <Grid container gap={'40px'}>
                {/* If it is the current application, it is always shown; if it is the main application, it is shown only if the customer is the application owner */}
                <Zoom in hidden={!Boolean(application?.id) || (isCurrentApplication ? false : hasApplicationParent ? false : !isApplicationOwner)}>
                    <Grid item xs={12}>
                        <Skeleton show={isLoading} height={100}>
                            <ApplicationStatusAlert application={application} />
                        </Skeleton>
                    </Grid>
                </Zoom>

                {(!isLoading && showShortcuts(application, (currentStage?.sort_order || 0) == 0)) && (
                    <Grid item xs={12}>
                        <Shortcuts
                            application={application!}
                            setCancelApplicationOpen={setCancelApplicationOpen}
                            setCoaccreditOpen={setCoaccreditOpen}
                        />
                    </Grid>
                )}
            </Grid>
        </Card >
    )
}