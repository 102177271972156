import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        height: 'calc(100vh - 70px)',
        display: 'flex',
        justifyContent: 'center',
        background: 'rgba(117, 139, 183, 0.04)',
        overflow: 'hidden',
    },
    mainContainer: {
        width: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -70
    },
    container: {
        width: '100%',
        maxWidth: 1440,
        height: 'fit-content',
        padding: '47px 60px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 16px',
        }
    },
    hero: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '0px 10px 50px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    heroTexts: {
        '& > h3': {
            color: theme.palette.primary.main,
            fontSize: 60,
            fontWeight: 'bold',
            maxWidth: 743,
            marginBottom: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: 38,
                textAlign: 'center'
            }
        },
        '& > p': {
            maxWidth: 743,
            fontSize: 16,
            marginBottom: 32,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center'
            }
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > h3': {
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 8
        },
        '& > p': {
            fontSize: 20,
            marginBottom: 32
        }
    },
    image: {
        '& svg': {
            maxWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 14
        }
    },
    button: {
        minWidth: 210,
        minHeight: 40,
        color: '#fff',
    }
}));