import { Grid, Tab, Tabs, Typography } from '@mui/material'
import { Application } from "src/types"
import { useStyles } from "./styles"

import { useDispatch, useSelector } from 'react-redux';
import { applicationActions } from 'src/store/application/actions';
import { getApplicationParentId, useIsMobile } from 'src/helpers';

interface RelatedApplicationsTabsProps {
    application?: Application
    selectedApplicationId: string
    setSelectedApplicationId: React.Dispatch<React.SetStateAction<string>>
}

export function RelatedApplicationsTabs({ application, selectedApplicationId, setSelectedApplicationId }: RelatedApplicationsTabsProps) {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const relatedApplications = useSelector(state => state.relatedApplications.data)
    const applicationParentId = getApplicationParentId(application)
    const currentTab = applicationParentId
        ? applicationParentId === selectedApplicationId ? 0 : 1
        : application?.id === selectedApplicationId ? 0 : relatedApplications.findIndex(({ related_application_workflow_id }) => related_application_workflow_id === selectedApplicationId) + 1

    function handleSelectApplication(applicationId: string) {
        setSelectedApplicationId(applicationId)
        dispatch(applicationActions.getApplication(applicationId, { partialLoading: true }))
    }

    return applicationParentId ? (
        <Grid container gap={1} className={classes.tabsContainer}>
            <Grid
                item
                onClick={() => handleSelectApplication(applicationParentId!)}
                className={cx(classes.tab, {
                    [classes.selectedTab]: applicationParentId === selectedApplicationId
                })}
            >
                <Typography>
                    Solicitud de crédito
                </Typography>
            </Grid>
            <Grid
                item
                onClick={() => handleSelectApplication(application?.id!)}
                className={cx(classes.tab, {
                    [classes.selectedTab]: application?.id === selectedApplicationId
                })}
            >
                <Typography>
                    Coacreditado
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <Tabs
            value={currentTab}
            variant={relatedApplications.length > 2 && isMobile ? 'scrollable' : 'standard'}
            className={classes.tabsContainer}
            classes={{
                root: classes.tabsRoot,
                flexContainer: classes.tabsFlexContainer,
                scrollButtons: cx({
                    [classes.tabsScrollButtons]: relatedApplications.length > 2
                })
            }}
        >
            <Tab
                label="Solicitud principal"
                className={classes.tab}
                onClick={() => handleSelectApplication(application?.id!)}
                classes={{
                    root: classes.tabRoot,
                    selected: classes.selectedTab,
                }}
            />
            {relatedApplications.map((relatedApplication, index) => (
                <Tab
                    key={relatedApplication.id}
                    label={`Coacreditado ${index + 1}`}
                    className={classes.tab}
                    onClick={() => handleSelectApplication(relatedApplication.related_application_workflow_id!)}
                    classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab
                    }}
                />
            ))}
        </Tabs>
    )
}