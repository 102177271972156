import { call, put, takeLatest, select, debounce, delay } from "redux-saga/effects";
import { sagaWrapper, getKrediParams, getLogRocketSessionUrl, getApplicationSegmentProperties, segment, isValidId, navigate, getApplicationResultURL } from "src/helpers";
import { ApplicationService, AuthService, Service } from "src/services";
import { Action, Response, Application, Organization, Workflow, ActionableType } from "src/types";
import { RootState } from "..";
import { APPLICATION_TYPES } from "./types";
import queryString from 'query-string';
import { snackbarActions } from "src/store/snackbar/actions";
import { authActions } from "src/store/auth/actions";
import { customerActions } from "src/store/customer/actions";
import _ from "lodash";
import { applicationActions } from "./actions";
import { questionActions } from "../questions/actions";
import { APPLICATION_EVALUATOR_FIELDS } from "./evaluatorFields/types";

export * from "./categories/sagas";
export * from "./rejectedDocuments/sagas";
export * from "./evaluatorFields/sagas";

const scoringCustomerService = new Service('customer', { api: 'scoring', disableNamespace: true })
const workflowService = new Service('workflows', { isPublic: true })
const eventTriggerService = new Service('event_triggers')

export function* setApplication(application: Application) {
	const { from } = queryString.parse(window.location.search);
	yield put(applicationActions.setApplication(application));

	const additionalParams = `application_id=${application?.id}&application_status=${application?.status}`
	const queryParams = `${from ? `?from=${from}&` : '?'}${additionalParams}`
	yield navigate.to(`/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}${queryParams}`);
}

function* getApplications() {
	const response: Response = yield call(ApplicationService.all, {});
	yield put(applicationActions.setApplications(response.data));
}

function* createApplication({ payload }: Action) {
	const logRocketUrl = getLogRocketSessionUrl();
	const { referral_code, utm_data, answers } = getKrediParams();

	const response: Response = yield call(ApplicationService.create, {
		application_workflow: {
			[isValidId(payload.workflowId) ? 'workflow_id' : 'workflow_slug']: payload.workflowId,
			session: {
				screen_recording: logRocketUrl,
				utm_data,
				code: referral_code
			}
		}
	});

	segment.track('Application', 'Created', {
		category: payload.workflowTitle,
		action: 'Application Started',
		location: 'Web App',
	})

	if (!_.isEmpty(answers)) {

		yield call(setApplication, response.data);
		sessionStorage.setItem('answers', JSON.stringify(answers))

	} else {
		yield call(setApplication, response.data);
	}

	yield put(authActions.setLogRocketSession(logRocketUrl));
}

function* createApplicationByRootSlug({ payload }: Action) {
	const logRocketUrl = getLogRocketSessionUrl();
	const { referral_code, utm_data, answers } = getKrediParams();

	const organization: Organization = yield select((state: RootState) => state.organization)
	const workflowResponse: Response = yield call(workflowService.all, {
		search: {
			organization_slug_eq: organization.slug,
			slug_eq: payload.workflowSlug,
		}
	})

	if (workflowResponse.data?.length > 0) {
		const workflow: Workflow = workflowResponse.data[0]

		// Create application only if it has an active version
		if (workflow?.active_version_id) {

			const response: Response = yield call(ApplicationService.create, {
				application_workflow: {
					workflow_id: workflow.active_version_id,
					session: {
						screen_recording: logRocketUrl,
						utm_data,
						code: referral_code
					}
				}
			});

			segment.track('Application', 'Created', {
				category: payload.workflowTitle,
				action: 'Application Started',
				location: 'Web App',
			})

			if (!_.isEmpty(answers)) {

				yield call(setApplication, response.data);
				sessionStorage.setItem('answers', JSON.stringify(answers))

			} else {
				yield call(setApplication, response.data);
			}

			yield put(authActions.setLogRocketSession(logRocketUrl));

		} else {
			yield put(applicationActions.set('isLoading', false))
			yield navigate.to('/')
		}
	} else {
		yield put(applicationActions.set('isLoading', false))
		yield navigate.to('/')
	}
}

function* updateApplication({ payload }: Action) {
	const { id } = payload;
	const response: Response = yield call(ApplicationService.update, id, {});
	yield put(applicationActions.setApplication(response.data));
}

function* destroyApplication({ payload }: Action) {
	const { id } = payload;
	yield call(ApplicationService.destroy, id);
	segment.track('Application', 'Deleted', {
		id,
		action: 'Application Deleted',
		location: 'Web App',
	})
	yield put(applicationActions.getApplications());
}

function* continueApplication({ payload }: Action) {
	const response: Response = yield call(ApplicationService.findOne, payload.id);

	if ((response?.data?.previous_section === null || response?.data?.next_section === null)
		&& ["rejected", "in_progress"].includes(response?.data?.status || "")) {
		navigate.to('/')
	} else {
		const preFillAnswers = sessionStorage.getItem('answers')
		if (preFillAnswers) {
			yield call(setApplication, {
				...response.data,
				current_values: { ...response.data.current_values, ...JSON.parse(preFillAnswers) }
			});
			sessionStorage.removeItem('answers')
		} else {
			yield call(setApplication, response.data);

		}
	}

	const currentLogRocketUrl: string = yield select((state: RootState) => state.auth.logRocketSession);
	if (!currentLogRocketUrl) {
		yield put(authActions.setLogRocketSession(getLogRocketSessionUrl()));
	}

	const applicationProperties: object = yield call(getApplicationSegmentProperties, 'Application Continued');
	segment.track('Application', 'Continued', applicationProperties);
}

function* getApplication({ payload }: Action) {
	const response: Response = yield call(ApplicationService.findOne, payload.id);
	yield put(applicationActions.setApplication(response.data));

	const applicationProperties: object = yield call(getApplicationSegmentProperties, 'Application Viewed');
	segment.track('Application', 'Viewed', applicationProperties)
}

function* cancelApplication({ payload }: Action) {
	yield call(ApplicationService.patchPath, `${payload.id}/cancel`, {});
	yield segment.track('Application', 'Cancelled', {
		id: payload.id,
		action: 'Application Cancelled',
		location: 'Web App',
	})

	if (payload.from === 'list') {
		yield put(customerActions.hasPendingApplication());
		yield put(applicationActions.getApplications());
	}
}

function* applicationFailed() {
	const application: Application = yield select((state: RootState) => state.applications.application);
	const backup: object = yield select((state: RootState) => state.questions.answersBackup);
	yield call(setApplication, {
		...application,
		current_values: backup
	});
	yield put(questionActions.answersBackup({}))
}

function* publish({ payload }: Action) {
	const application: Application = yield select((state: RootState) => state.applications.application);
	yield call(ApplicationService.patchPath, `${payload.id}/process_section`, {});
	yield call(ApplicationService.patchPath, `${payload.id}/publish_stage`, {});

	const applicationProperties: object = yield call(getApplicationSegmentProperties, 'Application Updated');
	segment.track('Application', 'Updated', {
		...applicationProperties,
		updated_resource: 'stage',
		status: 'in_review',
		label: 'in_review'
	});

	// if (application?.predetermined_workflow) {
	// 	yield navigate.to(`/application/${application.id}/received`);
	// } else {
	const hasQualifier: boolean = yield call(
		scoringCustomerService.getPath,
		`${application.workflow_id}/qualifiers/${application.current_stage?.id}/any`,
		false
	)

	if (hasQualifier) {
		yield put(authActions.setScoringData(undefined))
		yield navigate.to(`/application/${application.id}/running_scoring`);
	} else {
		yield put(authActions.setScoringData({ message: 'in_review', stage_id: application.current_stage?.id }))
		yield navigate.to(getApplicationResultURL(application, { customStatus: 'in_review' }))
	}

	//}
	yield put(applicationActions.set('isPublishing', false));
}

function* sendSmsCode({ payload }: Action) {
	const { id, data } = payload
	yield call(ApplicationService.patchPath, `${id}/send_sms_code`, data || {});
	yield put(snackbarActions.add('success', 'Te enviamos el código'));
}

function* handleGetError() {
	yield put(applicationActions.set('isPartialLoading', false));
	yield navigate.to('/')
}

function* getLastOneApplication() {
	const response: Response = yield call(ApplicationService.findOne, 'last_one');
	yield put(applicationActions.setLastOneApplication(response.data));
}

function* sendCurrentSectionMagicLink({ payload }: Action) {
	const organization: Organization = yield select((state: RootState) => state.organization);
	const application: Application = yield select((state: RootState) => state.applications.application);

	yield call(AuthService.sendMagicLink, {
		username: payload.email,
		client_id: organization.id!,
		response_type: 'code',
		redirect_uri: `/application/${application?.id}/assign`
	});
}

function* assignCustomer({ payload }: Action) {
	const response: Response = yield call(ApplicationService.updateCustomPath, `${payload.applicationId}/assign_customer`, {});
	yield call(setApplication, response.data);
}

function* getScoreResult({ payload }: Action) {
	const { applicationId, scoreId } = payload;
	const response: Response = yield call(ApplicationService.getPath, `${applicationId}/score_result/${scoreId}`);
	yield put(applicationActions.setScoreResult(response.data))
}

function* publishError() {
	yield put(applicationActions.set('isPublishing', false));
	yield navigate.goBack();
}

function* getLastScore({ payload }: Action) {
	const { applicationId, workflowId, stageId, applicationStatus } = payload
	const response: Response = yield call(scoringCustomerService.getPath, `${workflowId}/qualifiers/${stageId}/scores/${applicationId}/last_one`)
	let score = response.data;

	/**If has score set data and redirect to response page */
	if (score?.id) {
		yield put(authActions.setScoringData({
			application_workflow_id: applicationId,
			message: score.status,
			score_id: score.id,
			stage_id: stageId
		}))
		yield navigate.to(getApplicationResultURL(undefined, { customStatus: score.status || applicationStatus, applicationId }))
	}
}

function* createApplicationByEventTrigger({ payload }: Action) {
	const { applicationId, eventTriggerSlug, answers } = payload;
	const response: Response = yield call(eventTriggerService.patchPath, `${eventTriggerSlug}/execute`, {
		application_workflow_id: applicationId,
		answers
	}, false);

	const applicationResponse: Response = yield call(ApplicationService.findOne, response.result);
	yield call(setApplication, applicationResponse.data);

	//Clear past scoring data
	yield put(authActions.setScoringData())
}

export function* applicationSagas() {
	yield takeLatest(APPLICATION_TYPES.GET_APPLICATIONS, sagaWrapper(getApplications, applicationActions.setApplications([])));
	yield takeLatest(APPLICATION_TYPES.CREATE_APPLICATION, sagaWrapper(createApplication, applicationActions.set('isLoading', false)));
	yield takeLatest(APPLICATION_TYPES.UPDATE_APPLICATION, sagaWrapper(updateApplication, applicationActions.applicationFailed()));
	yield takeLatest(APPLICATION_TYPES.DESTROY_APPLICATION, sagaWrapper(destroyApplication));
	yield takeLatest(APPLICATION_TYPES.CONTINUE_APPLICATION, sagaWrapper(continueApplication));
	yield takeLatest(APPLICATION_TYPES.CANCEL_APPLICATION, sagaWrapper(cancelApplication));
	yield debounce(500, APPLICATION_TYPES.GET_APPLICATION, sagaWrapper(getApplication, applicationActions.handleGetError()));
	yield takeLatest(APPLICATION_TYPES.APPLICATION_FAILED, sagaWrapper(applicationFailed));
	yield takeLatest(APPLICATION_TYPES.PUBLISH, sagaWrapper(publish, applicationActions.publishError()));
	yield debounce(500, APPLICATION_TYPES.SEND_SMS_CODE, sagaWrapper(sendSmsCode));
	yield takeLatest(APPLICATION_TYPES.HANDLE_GET_ERROR, handleGetError);
	yield takeLatest(APPLICATION_TYPES.SEND_CURRENT_SECTION_MAGIC_LINK, sagaWrapper(sendCurrentSectionMagicLink));
	yield takeLatest(APPLICATION_TYPES.ASSIGN_CUSTOMER, sagaWrapper(assignCustomer));
	yield takeLatest(APPLICATION_TYPES.GET_LASTONE_APPLICATION, sagaWrapper(getLastOneApplication, applicationActions.set('isLoading', false)));
	yield takeLatest(APPLICATION_TYPES.GET_SCORE_RESULT, sagaWrapper(getScoreResult, applicationActions.setScoreResult()));
	yield takeLatest(APPLICATION_TYPES.PUBLISH_ERROR, sagaWrapper(publishError, applicationActions.publishError()));
	yield takeLatest(APPLICATION_TYPES.GET_LAST_SCORE, sagaWrapper(getLastScore))
	yield takeLatest(APPLICATION_TYPES.CREATE_APPLICATION_BY_ROOT_SLUG, sagaWrapper(createApplicationByRootSlug, applicationActions.set('isLoading', false)))
	yield takeLatest(APPLICATION_TYPES.CREATE_APPLICATION_BY_EVENT_TRIGGER, sagaWrapper(createApplicationByEventTrigger))
}