import { FieldType } from "src/types"
import { FIELDS_TYPES } from "./types"

function getOne(fieldId: string) {
	return {
		type: FIELDS_TYPES.GET_ONE,
		payload: { fieldId }
	}
}

function isLoading(isLoading: boolean) {
	return {
		type: FIELDS_TYPES.SET_ISLOADING,
		payload: { isLoading }
	}
}

function setOne(field?: any) {
	return {
		type: FIELDS_TYPES.SET_ONE,
		payload: { field }
	}
}

export const fieldsActions = {
	getOne,
	setOne,
	isLoading,
}