import { call, put, takeLatest } from "@redux-saga/core/effects"
import { sagaWrapper } from "src/helpers"
import { Service } from "src/services"
import { Action, Response } from "src/types"
import { fieldsActions } from "./actions"
import { FIELDS_TYPES } from "./types"

const fieldService = new Service('fields')

function* getOne({ payload }: Action) {
	const response: Response = yield call(fieldService.findOne, payload.fieldId)
	yield put(fieldsActions.setOne(response.data))
}

export function* fieldsSagas() {
	yield takeLatest(FIELDS_TYPES.GET_ONE, sagaWrapper(getOne, fieldsActions.setOne()))
}