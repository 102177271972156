import { tss } from "tss-react/mui"
import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        marginTop: 48,
        minHeight: 188
    },
    title: {
        fontSize: 34,
        fontWeight: 'bold',
        lineHeight: '40px',
        color: theme.palette.primary.main,
        paddingBottom: 16,
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            fontSize: 24,
            lineHeight: '30px',
            letterSpacing: '0.18px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '26px',
            letterSpacing: '0.15px',
            paddingBottom: 0
        }
    },
    tabsContainer: {
        position: 'absolute',
        top: -34
    },
    tab: {
        padding: '8px 16px',
        borderRadius: '10px 10px 0 0',
        height: 34,
        border: `1px solid #8892A0`,
        borderBottom: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
        '& > p': {
            color: '#8892A0',
            fontWeight: 500
        }
    },
    selectedTab: {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        transition: 'background-color 0.3s ease-in-out',
        '& > p': {
            color: theme.palette.primary.contrastText,
            fontWeight: 500
        }
    },
    stage: {
        fontSize: 14,
        color: theme.palette.primary.main,
        fontWeight: 500,
        '& path': {
            fill: theme.palette.primary.main
        }
    },
    status: {
        fontSize: 12,
        '& svg': {
            fontSize: '12px !important'
        }
    },
    pendingStatusColor: {
        color: theme.palette.warning.main,
    },
    completedStatusColor: {
        color: theme.palette.primary.main,
    },
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        '& svg': {
            fontSize: 14
        }
    }
}))