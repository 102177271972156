import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { customerReducer } from "./customer/reducer";
import { organizationReducer } from "./organization/reducer";
import { snackbarReducer } from "./snackbar/reducer";
import { workflowReducer } from "./workflow/reducer";
import { productApplicationReducer } from "./productApplication/reducer";
import { applicationReducer } from "./application/reducer";
import { stageReducer } from "./stages/reducer";
import { questionReducer } from "./questions/reducer";
import { messageReducer } from "./message/reducer";
import { providersReducer } from "./providers/reducer";
import { relatedApplcationsReducer } from "./relatedApplications/reducer";
import { attachmentsReducer } from "./attachments/reducer";
import { creditsReducer } from "./credits/credits/reducer";
import { accountStatementsReducer } from "./credits/accountStatements/reducer";
import { amortizationTabledReducer } from "./credits/amortizationTables/reducer";
import { contractsReducer } from "./credits/contracts/reducer";
import { proceduresReducer } from "./credits/procedures/reducer";
import { participantsReducer } from "./credits/participants/reducer";
import { directsDebitsReducer } from "./credits/directsDebits/reducer";
import { employmentsReducer } from "./employments/reducer";
import { bankAccountsReducer } from "./bankAccounts/reducer";
import { creditProposalsReducer } from "./creditProposals/reducer";
import { defaultWorkflowsReducer } from "./defaultWorkflows/reducer";
import { navigationReducer } from "./navigation/reducer";
import { refreshTokenReducer } from "./refreshToken/reducer";
import { applicationPaymentsReducer } from "./payments/reducer";
import { paymentsReducer } from "./credits/payment/reducer";
import { fieldsReducer } from "./fields/reducer";

export const rootReducer = combineReducers({
    // A
    applications: applicationReducer,
    attachments: attachmentsReducer,
    auth: authReducer,

    // B
    bankAccounts: bankAccountsReducer,

    // C
    credits: combineReducers({
        accountStatements: accountStatementsReducer,
        amortizationTables: amortizationTabledReducer,
        contracts: contractsReducer,
        credits: creditsReducer,
        directsDebits: directsDebitsReducer,
        participants: participantsReducer,
        payments: paymentsReducer,
        procedures: proceduresReducer,
    }),
    creditProposals: creditProposalsReducer,
    customer: customerReducer,

    // D
    default: combineReducers({
        workflows: defaultWorkflowsReducer,
    }),

    // E
    employments: employmentsReducer,

    // F
    fields: fieldsReducer,

    // M
    message: messageReducer,
    navigation: navigationReducer,

    // O
    organization: organizationReducer,

    // P
    payments: applicationPaymentsReducer,
    productApplication: productApplicationReducer,
    providers: providersReducer,

    // Q
    questions: questionReducer,

    // R
    refreshToken: refreshTokenReducer,
    relatedApplications: relatedApplcationsReducer,

    // S
    snackbar: snackbarReducer,
    stages: stageReducer,

    // W
    workflow: workflowReducer,

});