import { genderOptions, maritalStatusOptions } from "src/helpers";
import { Field, Address } from "src/types";

export const fields: Field[] = [
    {
        key: 'zipcode',
        name: 'zipcode',
        label: 'Código postal',
        required: true,
        field_type: 'text_field',
        sort_order: 1,
        placeholder: '',
        row: 1,
        width: 6,
        hidden: false,
        disabled: true
    },
    {
        key: 'state',
        name: 'state',
        label: 'Estado',
        required: true,
        field_type: 'state_select',
        sort_order: 2,
        placeholder: '',
        row: 2,
        width: 12,
        hidden: false,
        disabled: true
    },
    {
        key: 'city',
        name: 'city',
        label: 'Ciudad',
        required: true,
        field_type: 'text_field',
        sort_order: 3,
        placeholder: '',
        row: 3,
        width: 12,
        hidden: false,
        disabled: true
    },
    {
        key: 'municipality',
        name: 'municipality',
        label: 'Municipio',
        required: true,
        field_type: 'text_field',
        sort_order: 6,
        placeholder: '',
        row: 4,
        width: 12,
        hidden: false,
        disabled: true
    },
    {
        key: 'neighborhood',
        name: 'neighborhood',
        label: 'Colonia',
        required: true,
        field_type: 'text_field',
        sort_order: 2,
        placeholder: '',
        row: 5,
        width: 12,
        hidden: false,
        disabled: true
    },
    {
        key: 'street',
        name: 'street',
        label: 'Calle',
        required: true,
        field_type: 'text_field',
        sort_order: 3,
        placeholder: '',
        row: 6,
        width: 12,
        hidden: false,
        disabled: true
    },

    {
        key: 'street_number',
        name: 'street_number',
        label: 'Número exterior',
        required: true,
        field_type: 'text_field',
        sort_order: 6,
        placeholder: '',
        row: 7,
        width: 6,
        hidden: false,
        disabled: true
    },
    {
        key: 'interior_number',
        name: 'interior_number',
        label: 'Número interior',
        required: false,
        field_type: 'text_field',
        sort_order: 6,
        placeholder: '',
        row: 7,
        width: 6,
        hidden: false,
        disabled: true
    },
]

export let initialValues: Address = {
    street: '',
    street_number: '',
    interior_number: '',
    neighborhood: '',
    municipality: '',
    city: '',
    state: '',
    country: 'México',
    zipcode: ''
};
