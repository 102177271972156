import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    tabsContainer: {
        position: 'absolute',
        top: 12,
        left: 24,
        height: 34,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            left: 0
        }
    },
    tab: {
        padding: '10px 16px 8px',
        borderRadius: '10px 10px 0 0',
        height: 34,
        maxHeight: '34px !important',
        border: `1px solid #8892A0`,
        borderBottom: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            color: '#8892A0',
            fontWeight: 500
        },
        '& span': {
            height: 34
        }
    },
    selectedTab: {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        transition: 'background-color 0.3s ease-in-out',
        color: `${theme.palette.primary.contrastText} !important`,
        '& > button': {
            color: `${theme.palette.primary.contrastText} !important`,
            fontWeight: 500
        },
        '& > p': {
            color: `${theme.palette.primary.contrastText} !important`,
            fontWeight: 500
        },
    },
    tabsFlexContainer: {
        height: 34,
        gap: 8
    },
    tabsRoot: {
        minHeight: '34px !important'
    },
    tabRoot: {
        minHeight: '34px !important'
    },
    tabsScrollButtons: {
        width: 24,
        [theme.breakpoints.down('sm')]: {
            display: 'flex !important',
            alignItems: 'center'
        }
    }
}))