import { useEffect } from "react";
import { Loading, Table } from "src/components";
import { Grid } from "@mui/material";
import { useIsKredi, useIsMobile } from "src/helpers";

import { useDispatch, useSelector } from "react-redux";
import { employmentsActions } from "src/store/employments/actions";

import { getEmploymentColumns } from "./columns";
import { EmploymentMobileCard } from "./MobileCard";
import { useStyles } from "./styles";

interface EmploymentsTableProps {
    applicationId: string
}

export function EmploymentsTable({ applicationId }: EmploymentsTableProps) {
    const { classes } = useStyles()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const isKredi = useIsKredi()
    const employments = useSelector(state => state.employments.data)
    const isLoading = useSelector(state => state.employments.isLoading)
    const employmentColumns = getEmploymentColumns(isKredi)

    useEffect(() => {
        dispatch(employmentsActions.getAll('application', applicationId))
    }, [applicationId])

    return (
        <Loading isLoading={isLoading} variant="skeleton" skeletonsNumber={2}>
            {isMobile ? (
                <EmploymentMobileCard
                    applicationId={applicationId}
                    employments={employments}
                />
            ) : (
                <Grid container className={classes.card}>
                    <Table
                        data={employments}
                        columns={employmentColumns}
                        isLink
                        getHref={employment => `/home/applications/${applicationId}/employments/${employment.id}`}
                    />
                </Grid>
            )}
        </Loading >
    )
}