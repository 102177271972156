import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { EmptyState, Loading } from "src/components"
import { isProduction, isValidId } from "src/helpers"

import { useDispatch, useSelector } from "react-redux"
import { creditProposalsActions } from "src/store/creditProposals/actions"

import { CreditProposalStatusType, StatusConfirmation } from "../components"
import { ProposalCard } from "./ProposalCard"

import { useStyles } from "./styles"
import { applicationActions } from "src/store/application/actions"

export function CreditProposalsCardList() {
    const { applicationId }: any = useParams()
    const dispatch = useDispatch()

    const isLoading = useSelector(state => state.creditProposals.isLoading)
    const creditProposals = useSelector(state => state.creditProposals.data) || []
    const meta = useSelector(state => state.creditProposals.meta)

    const { classes } = useStyles(creditProposals.length)()

    const [confirmationModal, setConfirmationModal] = useState<{
        open: boolean
        proposalId?: string
        status?: CreditProposalStatusType
    }>({ open: false })

    const filteredCreditProposals = creditProposals.filter(p => !['draft', 'expired'].includes(p.status))
    const hasAcceptedProposal = filteredCreditProposals.some(p => p.status === 'accepted')

    useEffect(() => {
        if (isValidId(applicationId)) {
            dispatch(creditProposalsActions.getAll(applicationId, {
                page: 1,
                perPage: meta.perPage
            }))

            dispatch(applicationActions.getApplication(applicationId))
        }
    }, [applicationId])

    return (
        <>
            <StatusConfirmation
                open={confirmationModal.open}
                applicationId={applicationId}
                proposalId={confirmationModal.proposalId}
                newStatus={confirmationModal.status}
                handleConfirm={() => setConfirmationModal({ open: false })}
                handleCancel={() => setConfirmationModal({ open: false })}
                from="list"
            />

            <Loading isLoading={isLoading} variant="skeleton">
                <Box className={classes.root}>
                    <Box className={classes.cardListContainer}>

                        {filteredCreditProposals.length === 0 && (
                            <EmptyState
                                text="Por el momento no hay propuestas de crédito"
                                size="medium"
                            />
                        )}

                        {filteredCreditProposals.map((proposal, index) => {
                            return (
                                <ProposalCard
                                    key={index}
                                    proposal={proposal}
                                    existsProposalAccepted={hasAcceptedProposal}
                                    setConfirmationModal={(proposalId, status) => {
                                        setConfirmationModal({ open: true, proposalId, status })
                                    }}
                                    boxProps={{
                                        className: classes.proposalCard
                                    }}
                                />
                            )
                        })}

                    </Box>
                </Box>
            </Loading>
        </>
    )
}