import React from 'react';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { Organization } from "src/types";
import { components } from './components';
import { RootState } from 'src/store';
import { useSelector } from "react-redux";

const defaultPrimaryColor = '#1DAA6C';
const defaultSecondaryColor = '#2CC1A6';
const textColor = '#242C37'

export const SMALL_TABLET_BREAKPOINT = 744

export function createCustomTheme({ primary_color, secondary_color }: Organization) {
    const primaryColor = primary_color || defaultPrimaryColor;
    const secondaryColor = secondary_color || defaultSecondaryColor;

    return createTheme({
        palette: {
            primary: {
                main: primaryColor,
                contrastText: '#fff'
            },
            secondary: {
                main: secondaryColor,
                contrastText: '#ffcc00',
            },

            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            error: {
                main: "#f44336",
                light: '#fff1f1',
                dark: '#B00020',
            },
            text: {
                primary: textColor,
                secondary: '#8892A0',
            }
        },
        typography: {
            fontFamily: `${['Circular Std', 'Roboto'].join(',')} !important`,
            body1: {
                color: textColor
            },
        },
        components: components(primaryColor, secondaryColor),
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1024,
                lg: 1280,
                xl: 1920,
            }
        }
    });
}
