import { Button, ButtonProps } from "@mui/material";
import { useStyles } from "./styles"
import { HomeOutlined } from "@mui/icons-material";
import { Link } from "src/components";

export interface HomeButtonProps extends ButtonProps {
    isLoading?: boolean
}

export function HomeButton({ isLoading, className, ...rest }: HomeButtonProps) {
    const { classes, cx } = useStyles()

    return (
        <Link to="/">
            <Button
                variant="text"
                color="primary"
                size="large"
                className={cx(classes.button, className)}
                startIcon={<HomeOutlined />}
                disabled={isLoading}
                {...rest}
            >
                Volver a inicio
            </Button>
        </Link>
    )
}